import { parseError } from "../../../../utils/server";
import { showMessage } from "../../../../utils/notifications";
import { trigger_participant_remapping } from "../API";

export const triggerParticipantRemapping = async (contractReference?: string) => {
    try {
        if (!contractReference) {
            throw new Error('Invalid contract reference provided.');
        }

        const response = await trigger_participant_remapping(contractReference);
        if (response?.requestSuccessful) {
            showMessage(
                "Request Successful!", 
                "Successfuly sent request to trigger participant remapping.", 
                'success'
            );
        }
    } catch (error) {
        showMessage("Request Failed!", parseError(error), "error");
    }
};
