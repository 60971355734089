import {
  Alert,
  Button,
  Descriptions,
  notification,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {showError, showMessage, SUCCESS_MESSAGES} from "../../../../../../utils/notifications";
import {
  ELoanRequestProduct,
  LoanProductToDescriptionMap,
  LoanProduct
} from "../../../../LoanContractsManagement/utils/types";
import { buildUpdatedLoanOfferData } from "../../../../Workflow/Viewers/LoanUpdateViewer";
import { labels } from "../../labels";
import PermissionDenied from "../LoanOfferErrorDetails";
import { validateLoanRequestFilter } from "../LoanRequestTabs";
import ConfirmLoanOfferChangesModal from "../../../../../../components/Modals/ConfirmLoanOfferChangesModal";
import OVDConversionConfirmationModal from "../../../../../../components/Modals/OVDConversionConfirmationModal";
import CustomDescriptionItem from "./LoanOfferItem";
import {
  convertDateToHumanReadableFrequency,
  convertStatusToHumanReadable,
  parseAmountToCurrencyValue,
} from "../../../../../../utils/dataParser";
import { convertStatusTextToColor } from "../../../../../../utils/workflow";
import { LoanOfferStatus } from "../../utils/types";
import { useEditLoanOfferDetail } from "../../hooks/useEditLoanOfferDetail";
import { useConvertWCLToOVD } from "../../hooks/useConvertWCLToOVD";
import { useLoanConversionEligibility } from "../../hooks/useLoanConversionEligibility";
import {useLocation, useNavigate} from "react-router-dom";
import { updateRules } from "../../updateRules";
import omit from "lodash/omit";
import {delay} from "../../../../../../utils/server";
import { ELoanContractProduct } from "../../../../shared/types/loan-product";
import {canShowWorkingCapitalOnlyField} from "../../utils";

const { Text } = Typography;

/**
 * Helper function to format loan product display
 * @param productType The raw loan product type
 * @returns Formatted loan product display name
 */
const formatLoanProduct = (productType?: string): string => {
  if (!productType) return '';
  return LoanProductToDescriptionMap[productType as LoanProduct] || productType;
};

const LoanOfferDetails = ({
  loanDetails,
  loading,
  error,
}: {
  loanDetails: any;
  loading: boolean;
  error?: any;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loanRequestStatusTab } = location.state || {};

  const [formState, setFormState] = useState({
    editMode: false,
    editedData: { ...loanDetails },
    showPreviewChangesModal: false,
    showOVDConversionConfirmationPopUp: false,
    wclToOvdConversionFormIsActive: false,
  });
  const { editLoanOfferDetail, loading: editLoading } =
    useEditLoanOfferDetail();
  const { convertWCLToOVD, loading: isConvertingToOVD } = useConvertWCLToOVD();
  const {
    getLoanConversionEligibility,
    loading: isLoadingEligibility,
    conversionEligibility,
  } = useLoanConversionEligibility();
  const [isCountingDownToRefresh, setIsCountingDownToRefresh] = useState(false)
  const [initialManagementFee, setInitialManagementFee] = useState(0)
  const [managementFeePercentageOfLoan, setManagementFeePercentageOfLoan] = useState(0)
  const [isOVDConversion, setIsOVDConversion] = useState(false)


  useEffect(() => {
    if (loanDetails) {
      setInitialManagementFee(loanDetails?.originalManagementFee)
      setManagementFeePercentageOfLoan(
        (loanDetails?.originalManagementFee / loanDetails?.amount) * 100,
      );
    }
  }, [loanDetails]);

  const updateStateField = (updates: Record<string, any>) => {
    setFormState((prevState) => ({
      ...prevState,
      editedData: {
        ...prevState.editedData,
        ...updates,
        edited: true,
      },
    }));
  };

  const handleEdit = () => {
    setFormState((prevState) => ({
      ...prevState,
      editMode: true,
    }));
  };

  const handleDeepCompareOfferDetails = (editedData: any, loanDetails: any) => {
    for (const key in editedData) {
      // Skip the 'edited' flag
      if (key === "edited") return false;
      if (
        formState?.wclToOvdConversionFormIsActive &&
        editedData?.maxDefaultCountToTermination &&
        editedData?.regularizationPeriod
      ) {
        return true;
      } else {
        if (editedData[key] != loanDetails[key]) {
          return true; // Changes detected
        }
      }
    }
    return false; // No changes detected
  };

  const handleSaveChanges = () => {
    const { editedData } = formState;

    const errorValidation = validateLoanRequestFilter(
      removeOVDFieldsIfNeeded(editedData),
      removeOVDFieldsIfNeeded(updateRules),
      removeOVDFieldsIfNeeded(labels),
    );

    if (errorValidation) {
      return showError(errorValidation);
    }

    if (
      !handleDeepCompareOfferDetails(
        removeOVDFieldsIfNeeded(editedData),
        loanDetails,
      )
    ) {
      return showError("No changes made");
    }

    setFormState((prevState) => ({
      ...prevState,
      showPreviewChangesModal: true,
    }));
  };

  const removeOVDFieldsIfNeeded = (data: { [x: string]: any }) => {
    if (
      formState.wclToOvdConversionFormIsActive ||
      formState.editedData?.loanProduct === ELoanContractProduct.OVERDRAFT
    )
      return data;
    return omit(data, ["regularizationPeriod", "maxDefaultCountToTermination"]);
  };

  const handleUpdateLoanData = async () => {
    setIsCountingDownToRefresh(true)
    await editLoanOfferDetail(
      loanDetails?.loanProduct,
      loanDetails?.loanProduct === ELoanRequestProduct.WCL
        ? loanDetails?.reference
        : loanDetails?.offerReference,
      formState.editedData,
        async () => {
          try {
            //TODO : - This is a temporary fix to meet urgent business need, further refinement is required
            // Wait for 3 seconds
            await delay(3000)
            setFormState((prevState) => ({
              ...prevState,
              showPreviewChangesModal: false,
              editMode: false
            }));
            notification.open({
              message: SUCCESS_MESSAGES.EDIT_LOAN_OFFER.title,
              description: SUCCESS_MESSAGES.EDIT_LOAN_OFFER.message,
              onClose: () => {
                setIsCountingDownToRefresh(false)
                window.location.reload();
              },
            })
          } catch (error) {
          }
        },
        () => {
          setIsCountingDownToRefresh(false)
        }
    );
  };

  const handleConvertLoanProduct = async () => {
    if (!loanDetails) return;
    setIsCountingDownToRefresh(true)
    await convertWCLToOVD(
      {
        amount: Number(formState?.editedData?.amount ?? 0),
        dailyInterestRateInPercentage:
          formState?.editedData?.dailyInterestRateInPercentage,
        tenure: formState?.editedData?.tenure,
        loanTenureUnit: formState?.editedData?.loanTenureUnit,
        maxDefaultCountToTermination:
          formState.editedData?.maxDefaultCountToTermination,
        regularizationPeriod: formState.editedData?.regularizationPeriod,
        offerReference: loanDetails?.reference,
        managementFeeDiscountInPercentage:
          formState?.editedData?.managementFeeDiscountInPercentage,
      },
      async () => {
          //TODO : - This is a temporary fix to meet urgent business need, further refinement is required
          // Wait for 3 seconds
          await delay(3000)
        setFormState((prevState) => ({
          ...prevState,
          showPreviewChangesModal: false,
          editMode: false
        }));
        navigate(-1)
          notification.open({
            message: SUCCESS_MESSAGES.CONVERT_WCL_TO_OVD.title,
            description: SUCCESS_MESSAGES.CONVERT_WCL_TO_OVD.message,
            onClose: () => {
              setIsCountingDownToRefresh(false);
            },
          })
      },
        () => {
          setIsCountingDownToRefresh(false)
        }
    );
  };

  useEffect(() => {
    if (!formState.editMode) {
      setFormState((prevState) => ({
        ...prevState,
        editedData: {
          ...loanDetails,
          // Ensure required fields are included with default or initial values
          maxDefaultCountToTermination:
            loanDetails?.maxDefaultCountToTermination ?? 0,
          regularizationPeriod: loanDetails?.regularizationPeriod ?? 0,
        },
      }));
    }
  }, [loanDetails, formState.editMode]);

  useEffect(() => {
    const shouldRecalculateFees =
      formState.editedData?.managementFeeDiscountInPercentage &&
      formState.editMode;

    if (shouldRecalculateFees) {
      applyDiscountToManagementFee();
    }
  }, [
    formState.editedData?.managementFeeDiscountInPercentage,
    formState.editMode,
  ]);
  useEffect(() => {
    const shouldRecalculateFees =
      formState.editedData?.amount &&
      formState.editMode;

    if (shouldRecalculateFees) {
      adjustManagementFeeForLoanAmountChange();
    }
  }, [
    formState.editedData?.amount,
    formState.editMode,
    initialManagementFee
  ]);

  const handleBuildOfferDifference = () => {
    let comparedData = buildUpdatedLoanOfferData(
        loanDetails,
      formState.editedData,
      false,
    );
    if (comparedData.loanTenureUnit && !comparedData.tenure) {
      comparedData.tenure = loanDetails.tenure;
    }
    if (comparedData.loanTenureUnit && !comparedData.repaymentFrequency) {
      comparedData.repaymentFrequency = loanDetails.repaymentFrequency;
    }
    if (formState?.wclToOvdConversionFormIsActive) {
      comparedData = {
        ...comparedData,
        maxDefaultCountToTermination:
          formState?.editedData?.maxDefaultCountToTermination,
        regularizationPeriod: formState?.editedData?.regularizationPeriod,
        loanProduct: "OVERDRAFT",
      };
    }
    return comparedData;
  };

  const applyDiscountToManagementFee = () => {
    if (!loanDetails) return;
    const discountedManagementFee =
      initialManagementFee -
      (initialManagementFee *
        formState.editedData.managementFeeDiscountInPercentage) /
        100;

    updateStateField({discountedManagementFee});
  };

  const adjustManagementFeeForLoanAmountChange = () => {
    if (!loanDetails) return;
    const discountedManagementFee =
      initialManagementFee -
      (initialManagementFee *
        formState.editedData.managementFeeDiscountInPercentage) /
        100;
    const originalManagementFeeAmount =
      (managementFeePercentageOfLoan / 100) *
      formState.editedData.amount;
    updateStateField({discountedManagementFee});
    updateStateField({originalManagementFee: originalManagementFeeAmount});
    setInitialManagementFee(originalManagementFeeAmount);
  };

  useEffect(() => {
    const shouldGetLoanConversionEligibility =
      loanDetails?.loanProduct === ELoanRequestProduct.WCL &&
      loanDetails?.loanRequestStatus === LoanOfferStatus.PERUSING_LOAN_OFFER;

    if (loanDetails) {
      if (shouldGetLoanConversionEligibility) {
        getLoanConversionEligibility(loanDetails.reference);
      }
    }
  }, [loanDetails, loanRequestStatusTab]);

  const handleCancelEdit = () => {
    setFormState((prevState) => ({
      ...prevState,
      editMode: false,
      editedData: { ...loanDetails },
      wclToOvdConversionFormIsActive: false,
    }));
    setInitialManagementFee(loanDetails?.originalManagementFee)
    setIsOVDConversion(false)
  };

  const showConvertToOVDButton = loanDetails?.loanRequestStatus ===
      LoanOfferStatus.PERUSING_LOAN_OFFER &&
      loanDetails?.loanProduct ===
      ELoanContractProduct.WORKING_CAPITAL;


  const showWorkingCapitalOnlyField = useMemo(() => {
    return canShowWorkingCapitalOnlyField(isOVDConversion, formState, loanDetails)
  }, [loanDetails, isOVDConversion, formState]);

  if (error)
    return <PermissionDenied errorMessage="Loan Offer details not found" />;

  if (!loanDetails && !loading && !isLoadingEligibility)
    return <div>No loan details available</div>;

  return (
    <div>
      {loading || isLoadingEligibility ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          {conversionEligibility?.offerConversionEligibility ===
            "OFFER_CONVERSION_PENDING" && (
            <div className="ant-col-md-12 mb-4">
              <Alert
                message="The overdraft conversion request for this offer is awaiting approval."
                type="warning"
                showIcon
              />
            </div>
          )}
          {/* Descriptions block */}
          <Descriptions
            layout="vertical"
            contentStyle={{ marginTop: "-16px", maxWidth: "fit-content" }}
            column={{ xs: 1, md: 2, xl: 3 }}
          >
            <Descriptions.Item label={<Text strong>Loan Product</Text>}>
              {formatLoanProduct(loanDetails?.loanProduct)}
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Loan Offer Status</Text>}>
              <Tag
                color={convertStatusTextToColor(loanDetails?.loanRequestStatus)}
              >
                {convertStatusToHumanReadable(loanDetails?.loanRequestStatus)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Amount</Text>}>
              <CustomDescriptionItem
                value={formState.editedData?.amount}
                editMode={formState.editMode}
                setValue={(value) => updateStateField({ amount: value })}
                editProps={{ type: "number" }}
                parseAmount
              />
            </Descriptions.Item>
            {loanDetails?.processingFee &&
              Number(loanDetails?.processingFee) > 0 && (
                <Descriptions.Item label={<Text strong>Processing Fee</Text>}>
                  {parseAmountToCurrencyValue(loanDetails?.processingFee, "₦")}
                </Descriptions.Item>
              )}
            <Descriptions.Item label={<Text strong>Loan Tenure Unit</Text>}>
              <CustomDescriptionItem
                dateTenure
                value={formState.editedData?.loanTenureUnit}
                editMode={formState.editMode}
                dateProps={{ disallowHourly: true }}
                setValue={(value) =>
                  updateStateField({ loanTenureUnit: value })
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Tenure</Text>}>
              <CustomDescriptionItem
                value={formState.editedData?.tenure}
                editMode={formState.editMode}
                setValue={(value) => updateStateField({ tenure: value })}
                renderProps={{
                  suffix:
                    " " +
                    convertDateToHumanReadableFrequency(
                      formState.editedData?.tenure,
                      formState.editedData?.loanTenureUnit,
                    ),
                }}
                editProps={{ type: "number", maxLength: 5 }}
              />
            </Descriptions.Item>

            <Descriptions.Item
              label={<Text strong>Original Management Fee</Text>}
            >
              {parseAmountToCurrencyValue(initialManagementFee, "₦")}
            </Descriptions.Item>
            {formState.editedData?.discountedManagementFee ? (
              <Descriptions.Item
                label={<Text strong>Discounted Management Fee</Text>}
              >
                {parseAmountToCurrencyValue(
                  formState.editedData?.discountedManagementFee,
                  "₦",
                )}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item
                label={<Text strong>Discounted Management Fee</Text>}
              >
                {parseAmountToCurrencyValue(
                  formState.editedData?.discountedManagementFee,
                  "₦",
                )}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              label={<Text strong>Management Fee Discount Percentage</Text>}
            >
              <CustomDescriptionItem
                value={formState.editedData?.managementFeeDiscountInPercentage}
                editMode={formState.editMode}
                setValue={(value) =>
                  updateStateField({ managementFeeDiscountInPercentage: value })
                }
                editProps={{ type: "number" }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Daily Interest Rate Percentage</Text>}
            >
              <CustomDescriptionItem
                value={formState.editedData?.dailyInterestRateInPercentage}
                editMode={formState.editMode}
                setValue={(value) =>
                  updateStateField({ dailyInterestRateInPercentage: value })
                }
                renderProps={{ suffix: "% daily" }}
                editProps={{ type: "number" }}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Interest Calculation Frequency</Text>}
            >
              {formState.editedData?.interestCalculationFrequency}
            </Descriptions.Item>
            {showWorkingCapitalOnlyField && (
              <Descriptions.Item
                label={<Text strong>Repayment frequency</Text>}
              >
                <CustomDescriptionItem
                  value={formState.editedData?.repaymentFrequency}
                  editMode={formState.editMode}
                  setValue={(value) =>
                    updateStateField({ repaymentFrequency: value })
                  }
                  editProps={{ type: "number" }}
                  renderProps={{
                    prefix: "Every",
                    suffix:
                      " " +
                      convertDateToHumanReadableFrequency(
                        formState.editedData?.repaymentFrequency,
                        formState.editedData?.loanTenureUnit,
                      ),
                  }}
                />
              </Descriptions.Item>
            )}
            {showWorkingCapitalOnlyField && (
              <Descriptions.Item label={<Text strong>Moratorium In Days</Text>}>
                <CustomDescriptionItem
                  value={formState.editedData?.moratoriumInDays}
                  editMode={formState.editMode}
                  setValue={(value) =>
                    updateStateField({
                      moratoriumInDays: value,
                    })
                  }
                  editProps={{ type: 'number' }}
                />
              </Descriptions.Item>
            )}

            {/*Editing WCL to OVD Conversion form*/}
            {formState.wclToOvdConversionFormIsActive && (
              <>
                <Descriptions.Item
                  label={<Text strong>Max Default Count To Termination</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.maxDefaultCountToTermination}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField({ maxDefaultCountToTermination: value })
                    }
                    editProps={{ type: "number", maxLength: 5 }}
                  />
                </Descriptions.Item>

                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.regularizationPeriod}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField({ regularizationPeriod: value })
                    }
                    editProps={{ type: "number", maxLength: 5 }}
                    inputSuffix={
                      Number(formState.editedData?.regularizationPeriod) > 1
                        ? "days"
                        : "day"
                    }
                  />
                </Descriptions.Item>
              </>
            )}
            {/*Editing Overdraft Offer Details*/}
            {loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT && (
              <>
                <Descriptions.Item
                  label={<Text strong>Overdraft Account Number</Text>}
                >
                  {loanDetails?.loanAccountNuban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.regularizationPeriod}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField({ regularizationPeriod: value })
                    }
                    editProps={{ type: "number" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period Unit</Text>}
                >
                  {loanDetails?.regularizationPeriodUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Maximum Count to Termination</Text>}
                >
                  <CustomDescriptionItem
                    value={formState.editedData?.maxDefaultCountToTermination}
                    editMode={formState.editMode}
                    setValue={(value) =>
                      updateStateField({ maxDefaultCountToTermination: value })
                    }
                    editProps={{ type: "number" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Owner Code</Text>}
                >
                  {loanDetails?.businessOwnerCode}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>

          <Row justify="end" className="mt-8">
            {showConvertToOVDButton && !formState.editMode && (
              <Button
                type="primary"
                className="rounded mb-2 mr-2"
                onClick={() => {
                  setFormState((prevState) => ({
                    ...prevState,
                    showOVDConversionConfirmationPopUp: true,
                  }));
                  setIsOVDConversion(true);
                }}
                disabled={
                  isConvertingToOVD ||
                  editLoading ||
                  conversionEligibility?.offerConversionEligibility !==
                    "CONVERTIBLE"
                }
              >
                Convert To OVD
              </Button>
            )}

            {!formState.editMode &&
              loanDetails?.loanRequestStatus ===
                LoanOfferStatus.PERUSING_LOAN_OFFER && (
                <Button
                  type="primary"
                  className="rounded mb-2"
                  onClick={handleEdit}
                  disabled={isConvertingToOVD || editLoading}
                >
                  {editLoading ? "Saving details" : "Edit Loan Offer"}
                </Button>
              )}
            {formState.editMode && (
              <>
                <Button
                  type="primary"
                  className="rounded mb-2 mr-4"
                  onClick={handleSaveChanges}
                  disabled={editLoading || !formState.editedData?.edited}
                  loading={editLoading}
                >
                  {editLoading ? "Saving details" : "Save Changes"}
                </Button>
                <Button
                  type="ghost"
                  className="rounded mb-2"
                  onClick={handleCancelEdit}
                  disabled={editLoading}
                >
                  Cancel
                </Button>
              </>
            )}
          </Row>
        </>
      )}
      <ConfirmLoanOfferChangesModal
        visible={formState.showPreviewChangesModal}
        onConfirm={
          formState.wclToOvdConversionFormIsActive
            ? handleConvertLoanProduct
            : handleUpdateLoanData
        }
        onCancel={() =>
          setFormState((prevState) => ({
            ...prevState,
            showPreviewChangesModal: false,
          }))
        }
        loanDetails={loanDetails}
        ovdFormIsActive={formState.wclToOvdConversionFormIsActive}
        comparedData={
          formState?.showPreviewChangesModal ? handleBuildOfferDifference() : {}
        }
        loading={isConvertingToOVD || isCountingDownToRefresh}
        error={error}
      />
      <OVDConversionConfirmationModal
        visible={formState.showOVDConversionConfirmationPopUp}
        onConfirm={() => {
          setFormState((prevState) => ({
            ...prevState,
            showOVDConversionConfirmationPopUp: false,
            wclToOvdConversionFormIsActive: true,
            editMode: true,
          }));
        }}
        onCancel={() =>
          setFormState((prevState) => ({
            ...prevState,
            showOVDConversionConfirmationPopUp: false,
          }))
        }
      />
    </div>
  );
};

export default LoanOfferDetails;
