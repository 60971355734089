import { createTelemetry } from '@web-platform/telemetry';
import { useEffect } from 'react';
import config from '../config';

const telemetry = createTelemetry({
  collectorBaseUrl: config.TELEMETRY_BASE_URL,
  serviceName: config.TELEMETRY_SERVICE_NAME,
  showInBrowserConsole: config.TELEMETRY_IN_DEBUG_MODE,
  devMode: config.TELEMETRY_IN_DEV_MODE,
  environment: config.ENVIRONMENT,
  disable: !config.TELEMETRY_ENABLE,
});

export const { logging, metrics, tracing } = telemetry;

export const logger = logging.setup('logs');

export function useSetupWebVitalsAndTracing() {
  useEffect(() => {
    metrics.setupWebVitals();
    tracing.setup();
  }, []);
}
