import { Rules } from "./components/LoanRequestTabs";

// TODO : Switch from manual validation to zod
export const updateRules: Rules = {
  amount: { required: true, greaterThanZero: true },
  tenure: { required: true, digitsOnly: true, greaterThanZero: true },
  loanTenureUnit: { required: true },
  processingFee: { required: false, notLessThanZero: true },
  managementFee: { required: true, greaterThanZero: true },
  dailyInterestRateInPercentage: { required: true, greaterThanZero: true },
  repaymentFrequency: {
    required: true,
    digitsOnly: true,
    greaterThanZero: true,
  },
  regularizationPeriod: {
    required: true,
    digitsOnly: true,
    greaterThanZero: true,
  },
  maximumDefaultCountToTermination: {
    required: true,
    digitsOnly: true,
    greaterThanZero: true,
  },
  managementFeeDiscountInPercentage: {
    required: true,
    notGreaterThanHundred: true,
    greaterThanZero: false,
    decimalOnly: true
  },
  maxDefaultCountToTermination: {
    required: true,
    digitsOnly: true,
    greaterThanZero: true,
  },
  moratoriumInDays: {
    required: true,
    digitsOnly: true,
    decimalOnly: true
  }
};
