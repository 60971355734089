import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Spin, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import ServerErrorAlert from "../../../../components/ServerErrorAlert";
import useQueryLoanAgreements from "../hooks/useQueryLoanAgreements";
import { LoanAgreement } from "../utils/types";
import AgreementDetails from "./agreementDetails";
import { ELoanContractProduct } from "../../shared/types/loan-product";

const LoanAgreementsList = ({
  id,
  loanProduct,
}: {
  id: string;
  loanProduct: ELoanContractProduct;
}) => {
  const {
    loadingAgreements,
    loadingAgreementFile,
    loanAgreements,
    loanAgreementFile,
    loanAgreementPermissionError,
    fetchLoanAgreementFile,
  } = useQueryLoanAgreements({
    ref: id,
    loanProduct: loanProduct,
  });
  const [agreementModalParams, setAgreementModalParams] = useState<{
    showAgreementModalView: boolean;
    agreementDetails: LoanAgreement | null;
  }>({
    showAgreementModalView: false,
    agreementDetails: null,
  });
  const handleViewAgreement = async (row: LoanAgreement) => {
    setAgreementModalParams({
      ...agreementModalParams,
      showAgreementModalView: true,
      agreementDetails: row,
    });
    await fetchLoanAgreementFile(row.laeReference);
  };
  const tableColumns = [
    {
      title: "Agreement Type",
      dataIndex: "type",
      key: "type",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Signatory Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Last updated",
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      },
    },
    {
      title: "",
      dataIndex: "loanRequestReference",
      key: "loanRequestReference",
      render: (value: string, row: any) => {
        return (
          <Button
            type="primary"
            className="bg-[#0190fe] border-[#0190fe] rounded"
            onClick={() => handleViewAgreement(row)}
          >
            View Agreement
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Card title="Loan Agreement Details" className="mt-4">
        {loadingAgreements ? (
          <section className="flex items-center justify-center my-8">
            <Spin tip="Loading Details..." />
          </section>
        ) : loanAgreementPermissionError ? (
          <section className="flex flex-col items-center justify-center my-8">
            <LockOutlined className="text-5xl mb-5" />
            <ServerErrorAlert message="You do not have permission to view this section." />
          </section>
        ) : (
          <Table
            columns={tableColumns}
            dataSource={loanAgreements || []}
            pagination={false}
            rowKey={(record) => record.laeReference}
            loading={loadingAgreements}
          />
        )}
      </Card>
      {agreementModalParams.showAgreementModalView &&
        !loanAgreementPermissionError && (
          <AgreementDetails
            agreement={agreementModalParams?.agreementDetails}
            showAgreementModal={agreementModalParams?.showAgreementModalView}
            loading={loadingAgreementFile}
            agreementFile={loanAgreementFile}
            closeAgreement={() =>
              setAgreementModalParams({
                ...agreementModalParams,
                showAgreementModalView: false,
              })
            }
          />
        )}
    </>
  );
};
export default LoanAgreementsList;
