import { Descriptions, Spin, Tag, Typography } from "antd";
import {useMemo, useState} from "react";
import CustomDescriptionItem from "../../../LoanRequestsManagement/AllLoanRequests/components/loanDetails/LoanOfferItem";
import LoanOfferErrorDetails from "../../../LoanRequestsManagement/AllLoanRequests/components/LoanOfferErrorDetails";
import { convertDateToHumanReadableFrequency } from "../../../../../utils/dataParser";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import upperCase from "lodash/upperCase";
import { ArrowRightOutlined } from "@ant-design/icons";
import LoanProductTag from "../../../shared/ui/loan-product-tag";
import { ELoanContractProduct } from "../../../shared/types/loan-product";
import {canShowWorkingCapitalOnlyField} from "../../../LoanRequestsManagement/AllLoanRequests/utils";

const { Text } = Typography;
const UpdatedLoanOfferDetails = ({
  loanDetails,
  loading,
  error,
  updatedOffer,
}: {
  loanDetails: any;
  loading: boolean;
  error?: any;
  updatedOffer?: any;
}) => {
  const editMode = false;
  const [editedData, setEditedData] = useState({ ...loanDetails });

    const showWorkingCapitalOnlyField = useMemo(() => {
        const isOVDConversion =
            loanDetails?.loanProduct === ELoanContractProduct.WORKING_CAPITAL &&
            updatedOffer?.loanProduct === ELoanContractProduct.OVERDRAFT;
        const formState = { editMode: false };
        return canShowWorkingCapitalOnlyField(
            isOVDConversion,
            formState,
            loanDetails,
        );
    }, [loanDetails, updatedOffer]);

  if (error) {
    return (
      <LoanOfferErrorDetails
        isPermissionError={false}
        errorMessage="Loan Offer details not found"
      />
    );
  }

  return (
    <>
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <div className="loan-offer">
          <Descriptions
            layout="vertical"
            column={2}
            contentStyle={{ marginTop: "-16px", maxWidth: "fit-content" }}
          >
            <Descriptions.Item label={<Text strong>Loan Product</Text>}>
              {loanDetails?.loanProduct === updatedOffer?.loanProduct ||
              !updatedOffer?.loanProduct ? (
                // If no change in loanProduct, display only the original value
                <LoanProductTag loanProduct={loanDetails?.loanProduct} />
              ) : (
                // If loanProduct has changed, display both original and updated values
                <div className="flex items-center">
                  <LoanProductTag loanProduct={loanDetails?.loanProduct} />
                  <ArrowRightOutlined className="ml-3 mr-3" />
                 <LoanProductTag loanProduct={updatedOffer?.loanProduct} />
                </div>
              )}
            </Descriptions.Item>
            {loanDetails?.offerStatus && (
              <Descriptions.Item label={<Text strong>Loan Offer Status</Text>}>
                <Tag color={convertStatusTextToColor(loanDetails?.offerStatus)}>
                  {upperCase(loanDetails?.offerStatus)}
                </Tag>
              </Descriptions.Item>
            )}

            <Descriptions.Item label={<Text strong>Amount</Text>}>
              <CustomDescriptionItem
                value={editedData?.amount}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({ ...editedData, amount: value, edited: true })
                }
                editProps={{
                  type: "number",
                }}
                valueUpdated={updatedOffer?.amount}
                parseAmount
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Original Management Fee</Text>}
            >
              <CustomDescriptionItem
                value={editedData?.originalManagementFee}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    originalManagementFee: value,
                    edited: true,
                  })
                }
                editProps={{
                  type: "number",
                }}
                valueUpdated={updatedOffer?.originalManagementFee}
                parseAmount
              />
            </Descriptions.Item>

            <Descriptions.Item
              label={<Text strong>Discounted Management Fee</Text>}
            >
              <CustomDescriptionItem
                value={editedData?.discountedManagementFee}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    discountedManagementFee: value,
                    edited: true,
                  })
                }
                editProps={{
                  type: "number",
                }}
                valueUpdated={updatedOffer?.discountedManagementFee}
                parseAmount
              />
            </Descriptions.Item>

            <Descriptions.Item
              label={<Text strong>Management Fee Discount Percentage</Text>}
            >
              <CustomDescriptionItem
                value={`${editedData?.managementFeeDiscountInPercentage}%`}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    managementFeeDiscountInPercentage: value,
                    edited: true,
                  })
                }
                editProps={{
                  type: "number",
                }}
                valueUpdated={updatedOffer?.managementFeeDiscountInPercentage}
                renderProps={{
                  suffix: "",
                  suffixForUpdatedValue: "%",
                }}
              />
            </Descriptions.Item>

            <Descriptions.Item
              label={<Text strong>Interest Calculation Frequency</Text>}
            >
              {editedData?.interestCalculationFrequency}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Text strong>Daily Interest Rate Percentage</Text>}
              contentStyle={{
                padding: "0",
              }}
            >
              <CustomDescriptionItem
                value={editedData?.dailyInterestRateInPercentage}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    dailyInterestRateInPercentage: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix: "% daily",
                  suffixForUpdatedValue: "% daily",
                }}
                valueUpdated={updatedOffer?.dailyInterestRateInPercentage}
                editProps={{
                  type: "number",
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Tenure</Text>}>
              <CustomDescriptionItem
                value={editedData?.tenure}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    tenure: value,
                    loanTenure: value,
                    edited: true,
                  })
                }
                renderProps={{
                  suffix:
                    " " +
                    convertDateToHumanReadableFrequency(
                      editedData?.tenure,
                      editedData?.loanTenureUnit,
                    ),
                  suffixForUpdatedValue:
                    " " +
                    convertDateToHumanReadableFrequency(
                      updatedOffer?.tenure,
                      updatedOffer?.loanTenureUnit ||
                        editedData?.loanTenureUnit,
                    ),
                }}
                valueUpdated={updatedOffer?.tenure}
                editProps={{
                  type: "number",
                  maxLength: 5,
                }}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Text strong>Loan Tenure Unit</Text>}>
              <CustomDescriptionItem
                dateTenure
                value={editedData?.loanTenureUnit}
                editMode={editMode}
                setValue={(value) =>
                  setEditedData({
                    ...editedData,
                    loanTenureUnit: value,
                    edited: true,
                  })
                }
                valueUpdated={updatedOffer?.loanTenureUnit}
              />
            </Descriptions.Item>

            {/* WCL items */}
            {showWorkingCapitalOnlyField && (
              <>
                <Descriptions.Item
                  label={<Text strong>Repayment frequency</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.repaymentFrequency}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        repaymentFrequency: value,
                        edited: true,
                      })
                    }
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          editedData?.repaymentFrequency,
                          editedData?.loanTenureUnit,
                        ),
                      suffixForUpdatedValue:
                        " " +
                        convertDateToHumanReadableFrequency(
                          updatedOffer?.repaymentFrequency,
                          updatedOffer?.loanTenureUnit ||
                            editedData?.loanTenureUnit,
                        ),
                    }}
                    editProps={{
                      type: "number",
                    }}
                    valueUpdated={updatedOffer?.repaymentFrequency}
                  />
                </Descriptions.Item>
              </>
            )}

            {showWorkingCapitalOnlyField && (
              <Descriptions.Item label={<Text strong>Moratorium In Days</Text>}>
                <CustomDescriptionItem
                  value={`${editedData?.moratoriumInDays} Day(s)`}
                  editMode={editMode}
                  setValue={(value) =>
                    setEditedData({
                      ...editedData,
                      moratoriumInDays: value,
                      edited: true,
                    })
                  }
                  editProps={{
                    type: 'number',
                  }}
                  valueUpdated={updatedOffer?.moratoriumInDays}
                  renderProps={{
                    suffix: '',
                    suffixForUpdatedValue: ' Day(s)',
                  }}
                />
              </Descriptions.Item>
            )}

            {updatedOffer?.regularizationPeriod &&
              loanDetails?.loanProduct !== ELoanContractProduct.OVERDRAFT && (
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.regularizationPeriod ?? 0}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        regularizationPeriod: value,
                        edited: true,
                      })
                    }
                    renderProps={{
                      suffix: " days",
                      suffixForUpdatedValue: " days",
                    }}
                    editProps={{
                      type: "number",
                    }}
                    valueUpdated={updatedOffer?.regularizationPeriod}
                  />
                </Descriptions.Item>
              )}

            {updatedOffer?.maxDefaultCountToTermination &&
              loanDetails?.loanProduct !== ELoanContractProduct.OVERDRAFT && (
                <Descriptions.Item
                  label={<Text strong>Max Default Count To Termination</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.maxDefaultCountToTermination ?? 0}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        maxDefaultCountToTermination: value,
                        edited: true,
                      })
                    }
                    editProps={{
                      type: "number",
                    }}
                    valueUpdated={updatedOffer?.maxDefaultCountToTermination}
                  />
                </Descriptions.Item>
              )}

            {/* "OVD ITEMS" */}
            {loanDetails?.loanProduct === ELoanContractProduct.OVERDRAFT && (
              <>
                <Descriptions.Item
                  label={<Text strong>Overdraft Account Number</Text>}
                >
                  {loanDetails?.loanAccountNuban}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.regularizationPeriod}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        regularizationPeriod: value,
                        edited: true,
                      })
                    }
                    renderProps={{
                      prefix: "Every",
                      suffix:
                        " " +
                        convertDateToHumanReadableFrequency(
                          editedData?.regularizationPeriod,
                          editedData?.regularizationPeriodUnit,
                        ),
                      suffixForUpdatedValue:
                        " " +
                        convertDateToHumanReadableFrequency(
                          updatedOffer?.regularizationPeriod,
                          updatedOffer?.regularizationPeriodUnit ||
                            editedData?.regularizationPeriodUnit,
                        ),
                    }}
                    valueUpdated={updatedOffer?.regularizationPeriod}
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Regularization Period Unit</Text>}
                >
                  {loanDetails?.regularizationPeriodUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Maximum Count to termination</Text>}
                >
                  <CustomDescriptionItem
                    value={editedData?.maxDefaultCountToTermination}
                    editMode={editMode}
                    setValue={(value) =>
                      setEditedData({
                        ...editedData,
                        maxDefaultCountToTermination: value,
                        edited: true,
                      })
                    }
                    valueUpdated={updatedOffer?.maxDefaultCountToTermination}
                    editProps={{
                      type: "number",
                    }}
                  />
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Owner Code</Text>}
                >
                  {loanDetails?.businessOwnerCode}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </div>
      )}
    </>
  );
};
export default UpdatedLoanOfferDetails;
