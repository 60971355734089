import { ELoanContractProduct } from "../types/loan-product";

export const loanProductTypeToTagPropertiespMap = {
    [ELoanContractProduct.OVERDRAFT]: {
        text: "OVERDRAFT",
        color: "#1B97DB",
    },
    [ELoanContractProduct.AGENT_LOAN]: {
        text: "AGENT LOAN",
        color: "#3966D6",
    },
    [ELoanContractProduct.WORKING_CAPITAL]: {
        text: "WORKING CAPITAL LOAN",
        color: "#9B51E0",
    }
}
