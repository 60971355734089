export enum EUserAuthorities {
    admin = "admin",
    LOANS_BO_CAN_TERMINATE_OVERDRAFT_CONTRACT = "LOANS_BO_CAN_TERMINATE_OVERDRAFT_CONTRACT",
    WCL_PORTFOLIO_CAN_TRIGGER_CONTRACT_MP_PARTICIPANTS_REMAPPING = "WCL_PORTFOLIO_CAN_TRIGGER_CONTRACT_MP_PARTICIPANTS_REMAPPING"
}
export enum EUserRoles {
    admin = "admin",
}

export interface IAuthState {
    user: IUser | null;
    isAuthenticated: boolean;
    authError: string;
    authLoading: boolean;
    resendActivationMailLoading: boolean
}

export interface IUser {
    lastName: string;
    resetPassword: boolean;
    user_name: string;
    authenticationDomain: string;
    roles: EUserRoles[];
    branch: string;
    authorities: EUserAuthorities[];
    panicMode: boolean;
    client_id: string;
    deviceStatus: string;
    firstName: string;
    aud: string[];
    scope: string[];
    name: string;
    userType: string;
    exp: number;
    jti: string;
    username: string;
}
