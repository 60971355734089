import CONFIG_DEV from "./config-dev";
import CONFIG_PRODUCTION from "./config-production";
import CONFIG_STAGING from "./config-staging";
import { Environment } from "./type";

const configMap = {
  playground: CONFIG_DEV,
  test: CONFIG_DEV,
  dev: CONFIG_DEV,
  staging: CONFIG_STAGING,
  production: CONFIG_PRODUCTION,
};

const environment = (process.env.REACT_APP_ENV as Environment) || 'dev';

const config = configMap[environment];

export default config;
