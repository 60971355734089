import React from 'react';
import 'antd/dist/antd.min.css'
import './assets/App.css';
import './assets/utils.css'
import RouteModules from './routes';
import { useSetupWebVitalsAndTracing } from './utils/telemetry';

function App() {
  useSetupWebVitalsAndTracing();
  return <RouteModules />;
}

export default App;
