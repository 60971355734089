import { LoanRequestStatus } from "../loanRequestSlice/loanRequest.types";

export const maxCommentLength = 255;
export const minCommentLength = 6;

export const loanRequestStatusesWhereFirstFcoIsReAssignable = [
  LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH,
  LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION,
  LoanRequestStatus.AWAITING_VERIFICATIONS_DISPATCH,
  LoanRequestStatus.AWAITING_VERIFICATIONS_EXECUTION,
  LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL,
];

export const loanRequestStatusesWhereSecondFcoIsReAssignable = [
  ...loanRequestStatusesWhereFirstFcoIsReAssignable,
  LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL,
];

export const loanRequestStatusesWherePmIsReAssignable = [
    ...loanRequestStatusesWhereSecondFcoIsReAssignable,
    LoanRequestStatus.AWAITING_PM_APPROVAL,
];
