import { EUserAuthorities, IUser } from "../../../Auth/authSlice/authSlice.types";
import { CONTRACT_STATUSES_WHERE_PARTICIPANT_REMAPPING_CAN_NOT_BE_TRIGGERED } from "./constants"
import { LoanContractstatus } from "./types"

export const canTriggerParticipantRemapping = (user: IUser | null, status?: string) => {
    return user?.authorities?.includes?.(
        EUserAuthorities.WCL_PORTFOLIO_CAN_TRIGGER_CONTRACT_MP_PARTICIPANTS_REMAPPING
    ) && 
    !CONTRACT_STATUSES_WHERE_PARTICIPANT_REMAPPING_CAN_NOT_BE_TRIGGERED.includes(
        status as LoanContractstatus
    );
};
