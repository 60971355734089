import { Tag } from "antd";
import { loanProductTypeToTagPropertiespMap } from "../../constants/loan-product";
import { ELoanContractProduct } from "../../types/loan-product";

const LoanProductTag = ({ loanProduct }: { loanProduct: string }) => {
  return (
    <Tag
      color={
        loanProductTypeToTagPropertiespMap[loanProduct as ELoanContractProduct]
          ?.color
      }
    >
      {
        loanProductTypeToTagPropertiespMap[loanProduct as ELoanContractProduct]
          ?.text
      }
    </Tag>
  );
};

export default LoanProductTag;
