import { Descriptions, Typography } from "antd"
import { OverdraftContractDetails } from "../utils/types"
import { FC } from "react"
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser"
import { LoanContractStatusTag } from "../Components/LoanContractStatusTag"
import { useSearchParams } from "react-router-dom"
import LoanProductTag from "../../shared/ui/loan-product-tag"


interface Props {
    contractDetails: OverdraftContractDetails
    loanRequestDetails: any
}

const OVDDetails: FC<Props> = ({ contractDetails, loanRequestDetails }) => {
    const [searchParams] = useSearchParams();
    const loanProduct = searchParams.get('loan-product');
    return (
        <>
            <Descriptions
                layout="vertical"
                column={{ xs: 1, md: 2, xl: 3 }}
            >
                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessName}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Business Owner name
                        </Typography.Text>
                    }
                >
                    {loanRequestDetails?.businessOwnerName}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Amount
                        </Typography.Text>
                    }
                >
                    {parseAmountToCurrencyValue(contractDetails?.amount, "₦")}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Status
                        </Typography.Text>
                    }
                >
                    <LoanContractStatusTag status={contractDetails?.contractStatus} />
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Approval Date
                        </Typography.Text>
                    }
                >
                    {new Date(contractDetails?.acceptanceDate).toDateString()}
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Loan Product
                        </Typography.Text>
                    }
                >
                   <LoanProductTag loanProduct={loanProduct ?? ''} />
                </Descriptions.Item>

                <Descriptions.Item
                    label={
                        <Typography.Text strong>
                            Overdraft Account Number
                        </Typography.Text>
                    }
                >
                    {contractDetails?.loanAccountNuban}
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default OVDDetails
