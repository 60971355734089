import { Feature } from "./feature-config";
import { TConfig } from "./type";

const CONFIG_STAGING: TConfig = {
  ENVIRONMENT: 'staging',
  VERSION: "0.0.1",
  BASE_URL: "https://user-management.staging.teamapt.com/api/v1",
  LOCATION_BASE_URL:
    "https://location-v2.api.new.kuwego.staging.teamapt.com/api/v1",
  WCL_PORTFOLIO_BASE_URL:
    "https://portfolio-backoffice-service.staging.teamapt.com/api/v1",
  WCL_LOANS_BO_BASE_URL:
    "https://wcl.loans-bo-service.staging.teamapt.com/api/v1",
  cosmos_base_url: "https://cosmos.staging.teamapt.com",
  client_id: "wcl-user-management-client",
  auth_domain: "MONNIFY",
  WCL_MANAGEMENT_BASE_URL:
    "https://wcl-management-service.staging.teamapt.com/api/v1",
  excluded_features: [
    Feature.LOAN_PROGRAM_MANAGEMENT,
    Feature.LOCALGOVERNMENT_ACTIVATION,
  ],

  TELEMETRY_BASE_URL: 'https://hermes-ingest.moniepointinc.com/v1',
  TELEMETRY_SERVICE_NAME: 'loans-backoffice-web',
  TELEMETRY_IN_DEBUG_MODE: true,
  TELEMETRY_IN_DEV_MODE: true,
  TELEMETRY_ENABLE: true,
};

export default CONFIG_STAGING;
