import React, {useState, useEffect, useMemo} from "react";
import {
  Descriptions,
  Typography,
  Card,
  Button,
  Upload,
  message,
  Form,
  Input,
  Select, Spin, Tag,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { IGuarantor } from "../../utils/types";
import { useImageUpload } from "../../../../LoanContractsManagement/hooks/useImageUpload";
import useUpdateGuarantorDetails from "../../../../LoanContractsManagement/hooks/useUpdateGuarantorDetails";
import { useAppDispatch, useAppSelector } from "../../../../../../store";
import {
  fetchLGAs,
  fetchLGAsByStateCode,
  fetchStates,
} from "../../../../Utils/UtilsSlice/utilsSlice.thunks";
import useGuarantorEligibility from "../../../../LoanContractsManagement/hooks/useGuarantorEligibility";
import {convertStatusTextToColor} from "../../../../../../utils/workflow";
import upperCase from "lodash/upperCase";
import {mapLGACodeToName} from "../../utils";
const { Text } = Typography;
const { Option } = Select;

type GuarantorDetailsProps = {
  details?: IGuarantor[];
  loanRequestReference: string | null;
  loanRequestStatusTab: string
};

const GuarantorDetails = ({
  details,
  loanRequestReference,
  loanRequestStatusTab,
}: GuarantorDetailsProps) => {
  const [selectedGuarantorBVN, setSelectedGuarantorBVN] = useState<
    string | null
  >(null);
  const [proofFile, setProofFile] = useState<UploadFile | null>(null);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { uploading, uploadImages } = useImageUpload();
  const { isLoading: isUpdatingData, updateGuarantorDetails } =
    useUpdateGuarantorDetails();
  const {
    utils: { LGAs },
  } = useAppSelector((state) => state);
  const { states, LGAsByState } = useAppSelector((state) => state.utils);
  const bvns = useMemo(
    () => details?.map((guarantor) => guarantor.bvn) || [],
    [details],
  );
  const { isLoading: isEligibilityLoading, eligibilityStatuses, fetchEligibilityStatuses } =
    useGuarantorEligibility(
      loanRequestReference || "",
      bvns,
      loanRequestStatusTab,
    );

  const getEligibilityForBVN = (bvn: string) => {
    return eligibilityStatuses.find((status) => status.bvn === bvn);
  };

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!LGAs.length) {
      dispatch(fetchLGAs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleStateChange = (value: string) => {
    if (value) {
      form.setFieldsValue({ lgaCode: undefined });
      dispatch(fetchLGAsByStateCode(value));
    }
  };

  const getSelectedGuarantor = (
    bvn: string | null,
    details: IGuarantor[] | undefined,
  ): IGuarantor | null => {
    return details?.find((guarantor) => guarantor?.bvn === bvn) || null;
  };

  useEffect(() => {
    if (selectedGuarantorBVN) {
      const selectedGuarantor = getSelectedGuarantor(
        selectedGuarantorBVN,
        details,
      );
      if (selectedGuarantor) {
        const {
          phoneNumber,
          email,
          residentialAddress: { line1, line2, city},
        } = selectedGuarantor;
        form.setFieldsValue({
          phoneNo: phoneNumber,
          email,
          line1,
          line2,
          city,
        });
      }
    } else {
      form.resetFields();
    }
  }, [selectedGuarantorBVN, details, form]);

  const handleEditClick = (guarantorBVN: string) => {
    setSelectedGuarantorBVN(guarantorBVN);
    setProofFile(null);
  };

  const handleCancel = () => {
    setSelectedGuarantorBVN(null);
    setProofFile(null);
    form.resetFields();
  };

  const handleBeforeUpload = (file: RcFile) => {
    const isJpgOrPdf = ["image/jpeg", "image/png", "application/pdf"].includes(
      file.type,
    );
    if (!isJpgOrPdf) {
      message.error("You can only upload JPG, PNG, or PDF files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setProofFile(file);
    return false; // Prevent automatic upload
  };

  const handleRemove = () => {
    setProofFile(null);
  };

  const onFinish = async (values: any) => {
    if (!proofFile) {
      message.error("Please upload proof document.");
      return;
    }

    const imageReferences = await uploadImages([proofFile]);
    if (!imageReferences.length) {
      message.error("File upload failed. Please try again.");
      return;
    }

    const selectedGuarantor = getSelectedGuarantor(
      selectedGuarantorBVN,
      details,
    );
    const payload = {
      loanRequestReference: loanRequestReference!,
      bvn: selectedGuarantor?.bvn || "",
      updateRequest: {
        email: values.email,
        phoneNumber: values.phoneNo,
        residentialAddress: {
          line1: values.line1,
          line2: values.line2 || "",
          city: values.city,
          lgaCode: values.lgaCode,
        },
      },
      authorizationProof: imageReferences[0], // Assuming single image reference is used for proof
    };

    await updateGuarantorDetails({
      payload,
      callback: () => {
        fetchEligibilityStatuses();
        handleCancel();
      },
    });
  };

  if (!details || details.length === 0) {
    return <Text strong>No guarantor details available</Text>;
  }

  const renderGuarantorForm = () => (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ marginTop: "16px" }}
    >
      <Form.Item
        name="phoneNo"
        label="Phone Number"
        rules={[
          { required: true, message: "Phone number is required" },
          {
            pattern: /^[0-9]{10,15}$/,
            message: "Phone number must be between 10 and 15 digits",
          },
          {
            pattern: /^[0-9]+$/,
            message: "Phone number must contain only digits",
          },
        ]}
      >
        <Input placeholder="Enter phone number" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Please enter a valid email address" },
        ]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>
      <Form.Item
        name="line1"
        label="Line 1"
        rules={[
          { required: true, message: "Line 1 is required" },
          { max: 100, message: "Line 1 cannot exceed 250 characters" },
        ]}
      >
        <Input placeholder="Enter Line 1" />
      </Form.Item>
      <Form.Item
        name="line2"
        label="Line 2"
        rules={[{ max: 100, message: "Line 2 cannot exceed 250 characters" }]}
      >
        <Input placeholder="Enter Line 2 (Optional)" />
      </Form.Item>
      <Form.Item
        name="state"
        label="State"
        rules={[{ required: true, message: "Please select a state" }]}
      >
        <Select
          placeholder="Select a State"
          optionFilterProp="children"
          onChange={handleStateChange}
        >
          {states.map((state) => (
            <Option
              key={state.id}
              value={state.stateCode}
              name={state.stateName}
            >
              {state.stateName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
          name="lgaCode"
          label="LGA Code"
          rules={[{ required: true, message: "Please select the LGA" }]}
      >
        <Select placeholder="Select LGA">
          {LGAsByState.map((lga) => (
              <Option key={lga.id} value={lga.code} name={lga.name}>
                {lga.name}
              </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="city"
        label="City"
        rules={[
          { required: true, message: "City is required" },
          { max: 50, message: "City cannot exceed 250 characters" },
        ]}
      >
        <Input placeholder="Enter city" />
      </Form.Item>
      <Form.Item
        label={<Text strong>Upload Proof</Text>}
        required
        validateStatus={!proofFile ? "error" : undefined}
        help={!proofFile ? "Please upload proof document." : undefined}
      >
        <Upload
          beforeUpload={handleBeforeUpload}
          onRemove={handleRemove}
          fileList={proofFile ? [proofFile] : []}
          accept=".jpg,.jpeg,.png,.pdf"
          listType="picture"
        >
          {!proofFile && (
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          )}
        </Upload>
        {proofFile && proofFile?.type?.startsWith("image/") && (
          <img
            src={URL.createObjectURL(proofFile as RcFile)}
            alt="Proof Preview"
            style={{ width: "100px", marginTop: "10px" }}
          />
        )}
        {proofFile && proofFile.type === "application/pdf" && (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <InboxOutlined style={{ fontSize: "24px" }} />
            <span style={{ marginLeft: "8px" }}>{proofFile.name}</span>
          </div>
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: "8px" }}
          disabled={uploading || isUpdatingData}
        >
          Update
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Form.Item>
    </Form>
  );

  const renderGuarantorDetails = (guarantor: IGuarantor) => {
    const eligibility = getEligibilityForBVN(guarantor.bvn);
    const showEditButton = eligibility?.eligibleToUpdate;
    const isEditDisabled = eligibility?.hasPendingUpdateRequest;
    return (
      <>
        <Descriptions
          layout="vertical"
          column={1}
          bordered
          size="small"
          style={{ marginTop: "16px" }}
        >
          <Descriptions.Item label={<Text strong>Phone Number</Text>}>
            {guarantor?.phoneNumber || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Email</Text>}>
            {guarantor?.email || "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Residential Address</Text>}>
            {`${guarantor?.residentialAddress.line1},
             ${guarantor?.residentialAddress.line2 || ""}, 
             ${guarantor?.residentialAddress.city}, 
             ${mapLGACodeToName(guarantor?.residentialAddress.lgaCode, LGAs) || "N/A"}`}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Verification Status</Text>}>
            <Tag
              color={convertStatusTextToColor(
                eligibility?.verificationStatus ?? "",
              )}
            >
              {upperCase(eligibility?.verificationStatus)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <br />
        {showEditButton && (
          <>
            <Button
              type="link"
              onClick={() => handleEditClick(guarantor?.bvn)}
              style={{ marginTop: "16px" }}
              disabled={isEditDisabled}
              title={
                isEditDisabled
                  ? "An edit request is currently in progress."
                  : ""
              }
            >
              Edit
            </Button>
          </>
        )}
      </>
    );
  };
  return (
    <Spin
      spinning={isEligibilityLoading || uploading || isUpdatingData}
      tip="Loading..."
      className="flex justify-center items-center"
    >
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {details.map((guarantor: IGuarantor) => (
          <Card key={guarantor?.bvn} className="flex-1 mb-4 hover:shadow-lg relative">
            <Descriptions layout="vertical" column={1} bordered size="small">
              <Descriptions.Item label={<Text strong>Name</Text>}>
                {`${guarantor?.firstName} ${guarantor?.middleName ? guarantor?.middleName + " " : ""}${guarantor?.lastName}`}
              </Descriptions.Item>
            </Descriptions>
            {selectedGuarantorBVN === guarantor?.bvn
              ? renderGuarantorForm()
              : renderGuarantorDetails(guarantor)}
          </Card>
        ))}
      </div>
    </Spin>
  );
};

export default GuarantorDetails;
