import { Spin, Tag } from "antd";
import { FC, useEffect, useState } from "react";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import {
  LoanProductType,
  LoanProductConfig,
  LoanProduct,
  WorkflowRequestStatus,
} from "../../../LoanContractsManagement/utils/types";
import UpdatedLoanOfferDetails from "./UpdatedLoanOfferDetails";
import { useLoanOfferDetails } from "../../../LoanRequestsManagement/AllLoanRequests/hooks/useLoanOfferDetails";

interface LoanUpdateWorkflowViewerProps {
  src: any;
  data?: any;
}

/**
 * Function to build an updated loan offer data by comparing current and updated objects.
 *
 * @param currentObject - The current state of the loan offer data.
 * @param updatedObject - The updated state of the loan offer data.
 * @param isApprovedLoanWorkflow - Flag indicating if the loan workflow is approved.
 * @returns An object containing the differences between the current and updated loan offer data.
 */
export const buildUpdatedLoanOfferData = (
  currentObject: any,
  updatedObject: any,
  isApprovedLoanWorkflow = false,
) => {
  const updatedData: any = {};

  for (const key in currentObject) {
    if (Object.prototype.hasOwnProperty.call(currentObject, key)) {
      // Special case for loanProduct: Always mark as false since it will not change
      if (key === "loanProduct") {
        updatedData[key] = false;
      }
      // Compare current and updated values, mark as updated if they differ
      else if (currentObject[key] != updatedObject[key]) {
        updatedData[key] = updatedObject[key];
      } else {
        updatedData[key] = false;
      }
    }
  }
  return updatedData;
};

const LoanUpdateViewer: FC<LoanUpdateWorkflowViewerProps> = ({ src, data }) => {
  const json = src;

  const { loading, loanOfferDetails, getLoanOfferDetail, error } =
    useLoanOfferDetails();
  const [offerDiff, setOfferDiff] = useState<any>(null);
  const isApprovedLoanWorkflow =
    data?.requestStatus === WorkflowRequestStatus.APPROVED;
  // Find the full product name from the abbreviation using LoanProductConfig
  const loanProductType = json.request?.loanProduct as LoanProductType;
  const loanProduct = Object.entries(LoanProductConfig).find(
    ([_, value]) => value.abbreviation === loanProductType
  )?.[0] as LoanProduct;
  const workflowReference = data?.reference;
  useEffect(() => {
    if (json) {
      getLoanOfferDetail(
        loanProduct,
        json?.loanOfferRef as string,
        isApprovedLoanWorkflow,
        workflowReference,
      );
    }
  }, [getLoanOfferDetail, json]);
  useEffect(() => {
    if (loanOfferDetails && !loading) {
      const comparedData = buildUpdatedLoanOfferData(
        loanOfferDetails,
        isApprovedLoanWorkflow
          ? loanOfferDetails?.modificationRequest
          : json.request?.updateRequest[
              json.request?.loanProduct as "WCL" | "OVD"
            ],
        isApprovedLoanWorkflow,
      );
      // check if both tenureUnit and tenure Changed
      if (comparedData.loanTenureUnit && !comparedData.tenure) {
        comparedData.tenure = loanOfferDetails.tenure;
      }
      if (comparedData.loanTenureUnit && !comparedData.repaymentFrequency) {
        comparedData.repaymentFrequency = loanOfferDetails.repaymentFrequency;
      }
      setOfferDiff(comparedData);
    }
  }, [
    json.request?.loanProduct,
    json.request?.updateRequest,
    loading,
    loanOfferDetails,
  ]);
  return (
    <div className="p-[25px]">
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          <UpdatedLoanOfferDetails
            loanDetails={loanOfferDetails}
            loading={loading}
            error={error}
            updatedOffer={offerDiff}
          />
          {isApprovedLoanWorkflow && loanOfferDetails?.updateRequestStatus && (
            <div className="mt-4">
              <h3 className="text-sm font-semibold">Update Request Status</h3>
              <div className="mt-2">
                <Tag
                  color={convertStatusTextToColor(
                    loanOfferDetails?.updateRequestStatus,
                  )}
                >
                  {loanOfferDetails?.updateRequestStatus}
                </Tag>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default LoanUpdateViewer;
