import { TConfig } from "./type";

const CONFIG_DEV: TConfig = {
  ENVIRONMENT: 'dev',
  VERSION: "0.0.1",
  BASE_URL: "https://user-management.development.moniepoint.com/api/v1",
  LOCATION_BASE_URL:
    "https://loans-api-gateway.development.moniepoint.com/location/api/v1",
  WCL_PORTFOLIO_BASE_URL:
    "https://portfolio-backoffice-service.development.moniepoint.com/api/v1",
  WCL_LOANS_BO_BASE_URL:
    "https://wcl-loans-bo-service.development.moniepoint.com/api/v1",
  cosmos_base_url: "https://cosmos.development.moniepoint.com",
  client_id: "wcl-user-management-client",
  auth_domain: "MONNIFY",
  WCL_MANAGEMENT_BASE_URL:
    "https://wcl-management-service.development.moniepoint.com/api/v1",
  excluded_features: [],
  React_App_Mock: false,
  showLoanApprovalTimeline: false,

  TELEMETRY_BASE_URL: 'https://hermes-ingest.moniepointinc.com/v1',
  TELEMETRY_SERVICE_NAME: 'loans-backoffice-web',
  TELEMETRY_IN_DEBUG_MODE: true,
  TELEMETRY_IN_DEV_MODE: true,
  TELEMETRY_ENABLE: false,
};

export default CONFIG_DEV;
