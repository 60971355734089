import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
} from "antd";
import { useQueryLoanRequests } from "./hook";
import LoanRequestsTable from "./LoanRequestsTable";

const ViewLoanRequests = () => {
  const extraColumns = [
    {
      title: "",
      dataIndex: "loanRequestReference",
      key: "loanRequestReference",
      render: (value: string,row:any) => {
        return (
          <Link to={`/dashboard/loan-requests-mgt/details/${value}`}>
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      },
    },
  ]

  return (
    <LoanRequestsTable
      useQueryLoanRequests={useQueryLoanRequests}
      extraColumns={extraColumns}
      defaultSearchParams={{}}
      hasFilters
    />
  );
};

export default ViewLoanRequests;
