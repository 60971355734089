import { Spin } from "antd";
import { FC, useEffect, useState } from "react";

import {
  ELoanRequestProduct,
} from "../../LoanContractsManagement/utils/types";
import UpdatedLoanOfferDetails from "./LoanUpdateViewer/UpdatedLoanOfferDetails";
import { useLoanOfferDetails } from "../../LoanRequestsManagement/AllLoanRequests/hooks/useLoanOfferDetails";
import { ELoanContractProduct } from "../../shared/types/loan-product";

interface ActionParameters {
  overdraftOfferRequestDTO: WCLOfferToOverdraftRequestDetails;
  initialWclOfferResponse?: OriginalWCLLoanOfferDetails;
}

interface Props {
  src: ActionParameters;
}

interface WCLOfferToOverdraftRequestDetails {
  offerReference: string;
  regularizationPeriod: number;
  maxDefaultCountToTermination: number;
  amount: number;
  dailyInterestRateInPercentage: number;
  loanTenureUnit: string;
  tenure: number;
  managementFeeDiscountInPercentage: number;
}

interface OriginalWCLLoanOfferDetails {
  amount: number;
  managementFee: number;
  processingFee: number;
  repaymentFrequency: number;
  loanTenureUnit: string;
  tenure: number;
  dailyInterestRateInPercentage: number;
  hourlyInterestRateInPercentage: number;
  reference: string;
  loanRequestReference: string;
  loanProduct: string;
  offerStatus: string;
  interestCalculationFrequency: string;
  loanRequestStatus: string;
  originalManagementFee: number;
  discountedManagementFee: number;
  managementFeeDiscountInPercentage: number;
  moratoriumInDays: number;
}

/**
 * Builds the updated loan offer data by comparing the overdraft request with the original loan offer.
 *
 * @param overdraftRequest - The details of the overdraft offer request.
 * @param originalLoanOffer - The original WCL loan offer details.
 * @returns - The updated loan offer details.
 */
export const buildUpdatedLoanOfferData = (
  overdraftRequest: any,
  originalLoanOffer: any,
) => {
  const updatedData: any = { ...originalLoanOffer };
  let tenureChanged = false;

  for (const key in originalLoanOffer) {
    if (Object.prototype.hasOwnProperty.call(originalLoanOffer, key)) {
      // Track if the tenure has changed
      if (
        key === "tenure" &&
        overdraftRequest[key] !== originalLoanOffer[key]
      ) {
        tenureChanged = true;
      }
      updatedData[key] =
        overdraftRequest[key] !== originalLoanOffer[key]
          ? overdraftRequest[key]
          : false;
    }
  }

  // If tenure has changed but loanTenureUnit hasn't, ensure the updated tenure is reflected correctly
  if (
    tenureChanged &&
    overdraftRequest["loanTenureUnit"] === originalLoanOffer["loanTenureUnit"]
  ) {
    updatedData["tenure"] = overdraftRequest["tenure"];
  }
  // Set specific fields for the overdraft product
  updatedData.loanProduct = ELoanContractProduct.OVERDRAFT;
  updatedData.maxDefaultCountToTermination =
    overdraftRequest.maxDefaultCountToTermination;
  updatedData.regularizationPeriod = overdraftRequest.regularizationPeriod;

  return updatedData;
};

const LoanUpdateViewer: FC<Props> = ({ src }) => {
  const json = src;

  const { loading, loanOfferDetails, getLoanOfferDetail, error } =
    useLoanOfferDetails();
  const [offerDiff, setOfferDiff] = useState<
    OriginalWCLLoanOfferDetails | undefined
  >();

  useEffect(() => {
    if (json && !json?.initialWclOfferResponse && !loading) {
      getLoanOfferDetail(
        ELoanRequestProduct.WCL,
        json?.overdraftOfferRequestDTO?.offerReference,
      );
    }
  }, [getLoanOfferDetail, json]);

  useEffect(() => {
    if (!loading) {
      buildUpdatedLoanOfferData(
        json?.overdraftOfferRequestDTO,
        loanOfferDetails || json?.initialWclOfferResponse,
      );
      setOfferDiff(
        buildUpdatedLoanOfferData(
          json?.overdraftOfferRequestDTO,
          loanOfferDetails || json?.initialWclOfferResponse,
        ),
      );
    }
  }, [json?.overdraftOfferRequestDTO, loading, loanOfferDetails]);

  return (
    <div className="p-[25px]">
      {loading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Offer Details..." />
        </section>
      ) : (
        <>
          <UpdatedLoanOfferDetails
            loanDetails={loanOfferDetails || json?.initialWclOfferResponse}
            loading={loading}
            error={error}
            updatedOffer={offerDiff}
          />
        </>
      )}
    </div>
  );
};
export default LoanUpdateViewer;
